import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import style from "./Footer.module.scss"

const Footer = () => (
    <>
        <Container fluid={true} className={style.centreAlign}>
            <Row className={style.shareContainer}>
                <Col sm={3} className={style.commonCol1}>
                    <Row className={style.paddingTop}>
                        <Col>
                            <a className={style.linkStyle} href="/">
                                <Image className={style.logoImage} src="images/logo/why-documentaries-logo.png"
                                       rounded/>
                            </a>
                        </Col>
                    </Row>
                    <Row className={style.extraPaddingTop}>
                        <Col>
                            <a target={"_blank"} rel="noopener noreferrer"
                               href={"https://www.facebook.com/whydocumentaries"}>
                                <Image src="images/logo/fb-icon.png" width={65}/>
                            </a>
                            <a target={"_blank"} rel="noopener noreferrer"
                               href={"https://www.instagram.com/why_documentaries/"}>
                                <Image src="images/logo/insta-icon.png" width={65}/>
                            </a>
                            <a target={"_blank"} rel="noopener noreferrer"
                               href={"https://www.youtube.com/c/WhyDocumentaries"}>
                                <Image src="images/logo/youtube-icon.png" width={65}/>
                            </a>
                        </Col>
                    </Row>
                    <Row className={style.paddingTop}>
                        <Col>
                            <p className={style.bottomText}>&copy; Why Documentaries All Rights Reserved</p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={3} className={style.commonCol2}>
                    <p className={style.redText}>EXPLORE</p>
                    <a href={"/"}>
                        <p>HOME</p>
                    </a>
                    <a href={"/about"}>
                        <p>ABOUT</p>
                    </a>
                    <a href={"/services"}>
                        <p>SERVICES</p>
                    </a>
                    <a href={"/about#our-work"}>
                        <p>OUR WORK</p>
                    </a>
                    <br/>
                    <p className={style.redText}>VISIT</p>
                    <p>2/324 Crown St</p>
                    <p>Wollongong NSW 2500</p>
                </Col>
                <Col sm={3} className={style.commonCol3}>
                    <p className={style.redText}>CONTACT</p>
                    <a target={"_blank"} rel="noopener noreferrer"
                       href={"tel:+61242853545"}>
                        <p>(02) 4285 3545</p>
                    </a>
                    <a target={"_blank"} rel="noopener noreferrer"
                       href={"mailto:info@whydocumentaries.com.au"}>
                        <p>info@whydocumentaries.com.au</p>
                    </a>
                    <br/>
                    <div className={style.centreAlign}>
                        <a className={style.button}
                           target="_blank"
                           rel="noopener noreferrer"
                           href="https://us6.list-manage.com/contact-form?u=94e063b6935923f46c2c70789&form_id=c89428d457ba02a3821d2126e87a9b1a">
                            REQUEST A QUOTE
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
);

export default Footer
