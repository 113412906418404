import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import About from './pages/About';
import Services from './pages/Services';
import './app.scss'

export default () => (
    <Router>
        <Header/>
        <Switch>
            <Route exact path="/" component={About}/>
            <Route path="/about" component={About}/>
            <Route path="/services" component={Services}/>
            <Route path="/our-work" component={Services}/>
            <Route path="/contact" component={Services}/>
            <Redirect to="/"/>
        </Switch>
    </Router>
);
