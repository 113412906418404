import React from 'react';
import style from "./Logo.module.scss"
import BodyWrapper from "../components/BodyWrapper";
import { Row, Col } from "react-bootstrap";
import LogoContainer from "../components/LogoContainer";

const LocationPage = () => {
    return (
        <>
            <BodyWrapper>
                <Row>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-1.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-2.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-3.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-4.png"/></Col>
                </Row>
                <Row>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-5.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-6.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-7.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-8.png"/></Col>
                </Row>
                <Row>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-9.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-10.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-11.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-12.png"/></Col>
                </Row>
                <Row>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-13.gif"/></Col>
                    <Col><LogoContainer className={style.topPadding} logoUrl="images/logo/external/logo-14.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-15.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-16.png"/></Col>
                    <Col><LogoContainer logoUrl="images/logo/external/logo-17.png"/></Col>
                </Row>
            </BodyWrapper>
        </>
    );
};

export default LocationPage;
