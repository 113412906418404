import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import style from "./Contact.module.scss"

const Contact = () => (
    <Container fluid={true}>
        <Row>
            <Col className={style.blueBack} id={"contact"}>
                <Row className={style.allPadding}>
                    <Col>
                        <p className={style.whiteTextHeader}>
                            <img alt={"Facebook"}
                                 src={"/images/logo/location-small.png"}
                                 width={"30px"}
                            />
                            ADDRESS
                        </p>
                        <span className={style.whiteTextInfo}>2/324 Crown St</span>
                        <p className={style.whiteTextInfo}>Wollongong NSW 2500</p>
                    </Col>
                </Row>
                <Row className={style.allPadding}>
                    <Col>
                        <p className={style.whiteTextHeader}>
                            <img alt={"Facebook"}
                                 src={"/images/logo/phone-small.png"}
                                 width={"30px"}
                            />LET'S TALK!
                        </p>
                        <a target={"_blank"} rel="noopener noreferrer"
                           href={"tel:+61242853545"}>
                            <span className={style.whiteTextInfo}>(02) 4285 3545</span>
                        </a>
                    </Col>
                </Row>
                <Row className={style.allPadding}>
                    <Col>
                        <p className={style.whiteTextHeader}>
                            <img alt={"Facebook"}
                                 src={"/images/logo/email-small.png"}
                                 width={"30px"}
                            />
                            GENERAL ENQUIRIES
                        </p>
                        <a target={"_blank"} rel="noopener noreferrer"
                           href={"mailto:info@whydocumentaries.com.au"}>
                            <span className={style.whiteTextInfo}>info@whydocumentaries.com.au</span>
                        </a>
                    </Col>
                </Row>
            </Col>
            <Col className={style.contactForm}>
                <h3 className={style.redHeader}>LET’S WORK TOGETHER!</h3>
                <p className={style.subHeading}>Get in touch via the form below or lets chat on the phone, we’d love to
                    meet you.</p>
                <a className={style.button}
                   target="_blank"
                   rel="noopener noreferrer"
                   href="https://us6.list-manage.com/contact-form?u=94e063b6935923f46c2c70789&form_id=c89428d457ba02a3821d2126e87a9b1a">
                    CONTACT US
                </a>
            </Col>
        </Row>
    </Container>
);

export default Contact
