import React from 'react';
import { Image } from "react-bootstrap";
import style from "./LogoContainer.module.scss"

const LogoContainer = props => (
    <div className={style.logoWidthRestriction}>
        <Image fluid src={props.logoUrl}/>
    </div>
);

export default LogoContainer
