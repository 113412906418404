import React from 'react';
import style from './AboutVideos.module.scss'
import { Col, Image, Row } from "react-bootstrap";
import BodyWrapper from "./BodyWrapper";

const AboutVideos = () => {
    return (
        <BodyWrapper>
            <h3 id={"our-work"} className={style.boldHeading}>OUR WORK</h3>
            <p className={style.limitedWidth}>
                Our clients know that our content will speak direct to our target audiences –
                remember the content isn’t for you, it’s for the target audience,
                and it works.
            </p>
            <Row className={style.buffer} style={{ textAlign: "center" }} noGutters={true}>
                <Col sm={4}>
                    <Image width={"80%"} className={style.awardPadding} fluid src="images/awards/award-1.png"/>
                </Col>
                <Col sm={4}>
                    <Image width={"80%"} className={style.awardPadding} fluid src="images/awards/award-2.png"/>
                </Col>
                <Col sm={4}>
                    <Image width={"80%"} className={style.awardPadding} fluid src="images/awards/award-3.png"/>
                </Col>
            </Row>
            <Row className={style.buffer} noGutters={true}>
                <a className={style.button} href={"/"}>LEARN MORE</a>
            </Row>
            <Row className={style.buffer} noGutters={true}>
                <Col sm={3}>
                    <div className={style.imageWrapper}>
                        <Image fluid src="images/thumbnails/demo-video-1.png"/>
                        <div className={style.imageDescriptionLayer}>
                            <p className={style.imageDescription}>BRONSON -<br/>INVISIBLE DISABILITIES</p>
                        </div>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className={style.imageWrapper}>
                        <Image fluid src="images/thumbnails/demo-video-2.png"/>
                        <div className={style.imageDescriptionLayer}>
                            <p className={style.imageDescription}>CHRISTINA ANU -<br/>RIVALS TO RESPECT</p>
                        </div>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className={style.imageWrapper}>
                        <Image fluid src="images/thumbnails/demo-video-3.png"/>
                        <div className={style.imageDescriptionLayer}>
                            <p className={style.imageDescription}>MARK & JAMES -<br/>FIND COMMON GROUND</p>
                        </div>
                    </div>
                </Col>
                <Col sm={3}>
                    <div className={style.imageWrapper}>
                        <Image fluid src="images/thumbnails/demo-video-4.png"/>
                        <div className={style.imageDescriptionLayer}>
                            <p className={style.imageDescription}>TOM -<br/>IF IT’S FLOODED, FORGET IT</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </BodyWrapper>
    );
};

export default AboutVideos;
