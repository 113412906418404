import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import style from "./CrisCross.module.scss"

const CrisCross = props => (
    <Container fluid={true}>
        <Row sm={2} md={2}>
            <Col className={style.imageBackground}>
                <Image className={style.imageWidth} src="images/thumbnails/banner-1.png"/>
            </Col>
            <Col className={style.background}>
                <h1 className={style.redHeader}>NARRATIVES</h1>
                <p className={style.redText}>We ask why to help capture your target audience through the art of
                    story-telling.</p>
            </Col>
        </Row>
        <Row sm={2} md={2}>
            <Col className={style.background}>
                <h1 className={style.redHeader}>EDUCATIONAL</h1>
                <p className={style.redText}>We ask why to help teach your audience through visual mediums, making it
                    simple and effective.</p>
            </Col>
            <Col className={style.imageBackground}>
                <Image className={style.imageWidth} src="images/thumbnails/banner-2.png"/>
            </Col>
        </Row>
        <Row sm={2} md={2}>
            <Col className={style.imageBackground}>
                <Image className={style.imageWidth} src="images/thumbnails/banner-3.jpg"/>
            </Col>
            <Col className={style.background}>
                <h1 className={style.redHeader}>MEDIA CAMPAIGNS</h1>
                <p className={style.redText}>We ask why to know how to inspire people to listen, take action and make
                    change.</p>
            </Col>
        </Row>
    </Container>
);

export default CrisCross
