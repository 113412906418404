import React from 'react';
import style from './BodyWrapper.module.scss'
import { Col, Container, Row } from "react-bootstrap";

const BodyWrapper = props => {
    return (
        <Container className={style.containerPadding} fluid={true}>
            <Row>
                <Col className={style.leftRightPadding}/>
                <Col xs={10} md={9}>
                    {props.children}
                </Col>
                <Col className={style.leftRightPadding}/>
            </Row>
        </Container>
    );
};

export default BodyWrapper;
