import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import style from "./SubHeader.module.scss"

const SubHeader = () => (
    <Container className={style.preHeader} fluid={true}>
        <Row>
            <Col/>
            <Col xs={10} md={7}>
                <h1 className={style.subPreHeader}>QUESTION &nbsp;•&nbsp; FIND &nbsp;•&nbsp; SHARE</h1>
            </Col>
            <Col/>
        </Row>
    </Container>
);

export default SubHeader
