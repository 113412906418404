import React from 'react';
import BodyWrapper from "../components/BodyWrapper";
import Slider from "react-slick";
import style from "./CarouselQuotes.module.scss"

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const CarouselQuotes = () => (
    <>
        <BodyWrapper>
            <Slider className={style.divCentre} {...settings}>
                <div className={style.centreAlign}>
                    <p className={style.leftQuote}>&#8220;</p>
                    <p className={style.textContext}>
                        Sandra and the team at Why Documentaries have been a powerful source and tool which I have
                        tapped
                        into for over two decades. I have utilised Sandra’s expertise in digital communications for
                        national and state wide campaigns and government funded projects and could not be happier with
                        the results. In my previous senior executive roles and currently as a manager of programs in the
                        community sector, I know fi rst hand how diffi cult it is to fi nd a trusted company who can
                        provide a balanced fusion of powerful media communications expertise and on screen vision with
                        solid community sector knowledge and values. Our clients and consumers are our most precious
                        commodity. Developing trust to allow media to work directly with our communities is a difficult
                        task and one we do not give out lightly. It is with confidence we recommend Sandra and her team.
                        Whether you are developing digital solutions for ageing, disability, health or settlement sector
                        or the multicultural market, I would not go passed Sandra and her team at Why Documentaries.</p>
                    <h4 className={style.footerContext}>Terrie Leoleos - Ethnic Communities Council NSW</h4>
                    <p className={style.rightQuote}>&#8221;</p>
                </div>
                <div className={style.centreAlign}>
                    <p className={style.leftQuote}>&#8220;</p>
                    <p className={style.textContext}>
                        Sandra and the team at Why Documentaries have been a powerful source and tool which I have
                        tapped
                        into for over two decades. I have utilised Sandra’s expertise in digital communications for
                        national and state wide campaigns and government funded projects and could not be happier with
                        the results. In my previous senior executive roles and currently as a manager of programs in the
                        community sector, I know fi rst hand how diffi cult it is to fi nd a trusted company who can
                        provide a balanced fusion of powerful media communications expertise and on screen vision with
                        solid community sector knowledge and values. Our clients and consumers are our most precious
                        commodity. Developing trust to allow media to work directly with our communities is a difficult
                        task and one we do not give out lightly. It is with confidence we recommend Sandra and her team.
                        Whether you are developing digital solutions for ageing, disability, health or settlement sector
                        or the multicultural market, I would not go passed Sandra and her team at Why Documentaries.</p>
                    <h4 className={style.footerContext}>Terrie Leoleos - Ethnic Communities Council NSW</h4>
                    <p className={style.rightQuote}>&#8221;</p>
                </div>
                <div className={style.centreAlign}>
                    <p className={style.leftQuote}>&#8220;</p>
                    <p className={style.textContext}>
                        Sandra and the team at Why Documentaries have been a powerful source and tool which I have
                        tapped
                        into for over two decades. I have utilised Sandra’s expertise in digital communications for
                        national and state wide campaigns and government funded projects and could not be happier with
                        the results. In my previous senior executive roles and currently as a manager of programs in the
                        community sector, I know fi rst hand how diffi cult it is to fi nd a trusted company who can
                        provide a balanced fusion of powerful media communications expertise and on screen vision with
                        solid community sector knowledge and values. Our clients and consumers are our most precious
                        commodity. Developing trust to allow media to work directly with our communities is a difficult
                        task and one we do not give out lightly. It is with confidence we recommend Sandra and her team.
                        Whether you are developing digital solutions for ageing, disability, health or settlement sector
                        or the multicultural market, I would not go passed Sandra and her team at Why Documentaries.</p>
                    <h4 className={style.footerContext}>Terrie Leoleos - Ethnic Communities Council NSW</h4>
                    <p className={style.rightQuote}>&#8221;</p>
                </div>
            </Slider>
        </BodyWrapper>
    </>
);

export default CarouselQuotes
