import React from 'react';
import style from "./About.module.scss"
import { Container, Image, Row } from "react-bootstrap";
import SubHeader from "../components/SubHeader";
import Footer from "../components/Footer";
import CrossImage from "../components/CrossImage";
import CarouselQuotes from "../components/CarouselQuotes";
import Logo from "../components/Logo";
import AboutVideos from "../components/AboutVideos";

const AboutPage = () => {
    return (
        <>
            <Container fluid={true}>
                <Row>
                    {/*<Carousel className={style.fullWidth} interval={4 * 1000}>*/}
                    {/*    <Carousel.Item>*/}
                    <Image width={"100%"} height={"100%"} src="images/utils/header-hero-1.jpg"/>
                    {/*    </Carousel.Item>*/}
                    {/*</Carousel>*/}
                </Row>
            </Container>
            <SubHeader/>
            <AboutVideos/>
            <CrossImage/>
            <CarouselQuotes/>
            <Container fluid={true}>
                <Row className={style.blueBackground} sm={1}>
                    <span>SOME OF THE INSPIRING ORGANISATIONS WE'VE WORKED WITH</span>
                </Row>
            </Container>
            <Logo/>
            <Footer/>
        </>
    );
};

export default AboutPage;
