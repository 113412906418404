import React from 'react';
import style from "./Services.module.scss"
import { Col, Container, Image, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import CrisCross from "../components/CrisCross";
import BodyWrapper from "../components/BodyWrapper";
import Contact from "../components/Contact";

const AboutPage = () => {
    return (
        <>
            <Container className={style.servicesImage} fluid={true}>
                <Row>
                    <Image width={"100%"} height={"100%"} src="images/services/services-background.jpg"/>
                </Row>
            </Container>
            <Container className={style.preHeaderTop} fluid={true}>
                <Row>
                    <Col/>
                    <Col className={style.redBackground} xs={10} md={7}>
                        <h1 className={`${[style.boldHeading, style.subPreHeader].join(' ')}`}>OUR SERVICES</h1>
                    </Col>
                    <Col/>
                </Row>
                <Row>
                    <Col/>
                    <Col className={style.redBackground} xs={10} md={7}>
                        <Image className={style.separatorImage} src="images/utils/separator-white.png"/>
                    </Col>
                    <Col/>
                </Row>
                <Row>
                    <Col/>
                    <Col className={style.redBackground} xs={10} md={7}>
                        <h3 className={style.boldSubHeading}>We are your one stop shop for great video.</h3>
                    </Col>
                    <Col/>
                </Row>
                <Row className={style.redBackground}>
                    <Col/>
                    <Col className={style.redBackground} xs={10} md={7}>
                        <p className={style.subPreHeader}>
                            Rest assured the team at Why Documentaries cares a lot about
                            making really good impacting videos. Therefore we can work within your budget to create a
                            professional project, that you love and feel proud of. When you collaborate with us we never
                            leave
                            any information hidden or get too technical. You can expect to know the cost, time involved
                            and the
                            fine details of the process. In fact you can ask as many questions as you need before you
                            get
                            started to make sure you feel confident and happy about your project. After all creating
                            video
                            content is an investment that you and our team value highly.
                        </p>
                    </Col>
                    <Col/>
                </Row>
            </Container>
            <Container className={style.centreAlignHeading} fluid={true}>
                <Row>
                    <Col>
                        <Image height={"100px"} src="images/services/services-icon-01.png"/>
                        <p className={style.topHeader}>MULTICULTURAL MARKETING</p>
                    </Col>
                    <Col>
                        <Image height={"100px"} src="images/services/services-icon-02.png"/>
                        <p className={style.topHeader}>VIDEO PRODUCTION</p>
                    </Col>
                    <Col>
                        <Image height={"100px"} src="images/services/services-icon-03.png"/>
                        <p className={style.topHeader}>STUDIO EQUIPMENT HIRE</p>
                    </Col>
                    <Col>
                        <Image height={"100px"} src="images/services/services-icon-04.png"/>
                        <p className={style.topHeader}>SOCIAL MEDIA &amp; CONTENT CREATION</p>
                    </Col>
                </Row>
            </Container>
            <CrisCross/>
            <Container className={style.preHeader} fluid={true}>
                <Row>
                    <Col/>
                    <Col xs={10} md={7}>
                        <h1 className={style.subPreHeader}>THE PROCESS</h1>
                    </Col>
                    <Col/>
                </Row>
                <Row>
                    <Col/>
                    <Col xs={10} md={7}>
                        <Image className={style.separatorImage} src="images/utils/separator-white.png"/>
                    </Col>
                    <Col/>
                </Row>
                <Row>
                    <Col/>
                    <Col xs={10} md={7}>
                        <p className={style.subPreHeader}>
                            Rest assured the team at Why Documentaries cares a lot about
                            making really good impacting videos. Therefore we can work within your budget to create a
                            professional project, that you love and feel proud of. When you collaborate with us we never
                            leave
                            any information hidden or get too technical. You can expect to know the cost, time involved
                            and the
                            fine details of the process. In fact you can ask as many questions as you need before you
                            get
                            started to make sure you feel confident and happy about your project. After all creating
                            video
                            content is an investment that you and our team value highly.
                        </p>
                    </Col>
                    <Col/>
                </Row>
            </Container>
            <BodyWrapper>
                <Image fluid={true} src="images/utils/process.png"/>
            </BodyWrapper>
            <Container className={style.centreAlign} fluid={true}>
                <Row className={style.redSeparator} sm={1}>
                </Row>
            </Container>
            <Contact/>
            <Footer/>
        </>
    )
};

export default AboutPage;
