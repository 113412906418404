import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import style from "./Header.module.scss"

const Header = () => {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const MaxWidth = "900px"

    useEffect(() => {
        const mediaQuery = window.matchMedia(`(max-width: ${MaxWidth})`);
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => mediaQuery.removeListener(handleMediaQueryChange);
    }, []);

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches) {
            return setIsSmallScreen(true);
        }

        return setIsSmallScreen(false);
    };

    const toggleNav = () => {
        setNavVisibility(!isNavVisible);
    };

    return (
        <>
            {isSmallScreen &&
            <Row style={{ width: "100%" }}>
                <Col style={{ textAlign: "left", width: "100%" }}>
                    <a className={style.linkStyle} href="/">
                        <Image className={style.logoImage} src="images/logo/why-documentaries-logo.png"
                               rounded/>
                    </a>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <button className={style.buttonStyle}>
                        <Image onClick={toggleNav} style={{ marginTop: "30px" }}
                               src="images/utils/hamburger.png"
                               rounded
                               width={"50px"}
                        />
                    </button>
                </Col>
            </Row>}
            <Container fluid={true} className={style.headerContainer}>
                <Row>
                    <Col xs={20} className={`${style.linkNavItem} ${style.centreAlign}`}>
                        <Row>
                            {!isSmallScreen && <Col style={{ textAlign: "left", width: "100%" }}>
                                <a className={style.linkStyle} href="/">
                                    <Image className={style.logoImage} src="images/logo/why-documentaries-logo.png"
                                           rounded/>
                                </a>
                            </Col>}
                        </Row>
                    </Col>
                    <Col xs={20} className={`${style.linkNavItem} ${style.centreAlign} ${style.navLinks}`}>
                        {(!isSmallScreen || isNavVisible) && <>
                            <Col md="auto">
                                <p className={style.linkNavItem}>
                                    <a className={style.linkStyle} href="/about">ABOUT</a>
                                </p>
                            </Col>
                            <Col md="auto">
                                <p className={style.linkNavItem}>
                                    <a className={style.linkStyle} href="/services">SERVICES</a>
                                </p>
                            </Col>
                            <Col md="auto">
                                <p className={style.linkNavItem}>
                                    <a className={style.linkStyle} href="/about#our-work">OUR WORK</a>
                                </p>
                            </Col>
                            <Col md="auto">
                                <p className={style.linkNavItem}>
                                    <a className={style.linkStyle} href="/services#contact">CONTACT</a>
                                </p>
                            </Col>
                        </>}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Header
