import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import style from "./CrossImage.module.scss"

const CrossImage = () => (
    <>
        <Container fluid={true}>
            <Row sm={2} md={2}>
                <Col className={style.borderLess}>
                    <div className={`${style.centreAlign} ${style.paddingTop}`}>
                        <h3 className={`${style.centreAlign} ${style.headerText}`}>ABOUT US</h3>
                        <p className={`${style.centreAlign} ${style.displayText}`}>Why Documentaries believes in
                            positive story-telling, creating
                            an emotional journey that
                            leads people to solutions. We are passionate about guiding our clients through the creative
                            process with sensitivity and clarity, arriving at your fi nal destination of lasting
                            behavioural change and social impact.</p>
                        <div className={style.centreAlign}>
                            <a className={style.button} href={"/"}>LEARN MORE</a>
                        </div>
                    </div>
                </Col>
                <Col className={style.borderLess}>
                    <Image className={style.imageWidth} fluid src="images/thumbnails/square-1.png"/>
                </Col>
            </Row>
            <Row sm={2} md={2}>
                <Col className={style.borderLess}>
                    <Image className={style.imageWidth} fluid src="images/thumbnails/square-2.jpg"/>
                </Col>
                <Col className={style.borderLess}>
                    <div className={`${style.centreAlign} ${style.paddingTop}`}>
                        <h3 className={`${style.centreAlign} ${style.headerText}`}>WHY CHOOSE US?</h3>
                        <p className={`${style.centreAlign} ${style.displayText}`}>Visual storytellers since 2000, we
                            create content that people relate to, content that wins awards. We ask the hard questions to
                            fi nd the story behind the story. We go deeper to fi nd real stories that matter. We share
                            your stories to reach your target audience. We ask WHY.</p>
                        <br/>
                        <div className={style.centreAlign}>
                            <a className={style.button} href={"/"}>LEARN MORE</a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
);

export default CrossImage
